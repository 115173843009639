.admin-login {
  font-family: Montserrat;

  .login-card {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-section {
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
      background: #fff;
      border-radius: 13px;
      display: inline-block;
      height: auto;
      margin: 70px 0 70px 0;
      padding: 32px;
      position: relative;
      width: 50%;

      @media (max-width: 1024px) {
        width: 70%;
      }

      @media (max-width: 576px) {
        width: 95%;
      }

      h2 {
        text-align: center;
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 30px;
      }

      .fields {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 576px) {
          display: block;
        }

        label {
          width: 170px;
          font-weight: 600;
        }

        input {
          padding: 5px 10px;
          color: #4f4b58;
          background: #fefefe;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          transition: all 0.2s linear;
          font-size: 16px;
          width: 100%;

          &:focus {
            outline: thin dotted;
            border-color: #0170b9;
            box-shadow: none;
          }
        }
      }

      .login-btn {
        text-align: center;

        button {
          font-size: 16px;
          padding: 6px 30px;
          border-radius: 2px;
          color: #fff;
          border-color: #0170b9;
          background-color: #0170b9;
          border: 0;
          width: 100%;

          &:hover {
            background-color: #3a3a3a;
          }
        }
      }

      .optional-actions {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        div {
          a {
            color: #0170b9;
            font-size: 15px;
            line-height: 22px;

            &:hover {
              text-decoration: unset;
              color: #3a3a3a;
            }
          }
        }
      }
    }
  }
}