$green1: #0e7f4b;

body {
  background-color: rgb(255, 255, 255) !important;
  background-image: url("/img/d2.png"), url("/img/d1.png");
  background-position: right center, 0px 100px;
  background-repeat: no-repeat;
  background-size: 300px;
  min-height: 320px;
}

input {
  padding: 5px 10px !important;
  color: #4f4b58 !important;
  background: #fefefe;
  border: 1px solid #e5e5e5 !important;
  border-radius: 50px !important;
  transition: all 0.2s linear;
  font-size: 16px !important;
  width: 100% !important;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px 0px 5px 0px inset;

  &:focus {
    border: 1px solid #12491d !important;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
      rgb(0 0 0 / 30%) 0px 30px 60px -30px,
      rgb(10 37 64 / 35%) 0px -2px 6px 0px inset !important;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px 0px 5px 0px inset;
}

.outline-button {
  font-size: 16px !important;
  padding: 6px 30px !important;
  border-radius: 50px !important;
  color: #12491d !important;
  border-color: #12491d !important;
  background-color: #ffffff !important;
  border: 2px solid #12491d;
  font-weight: 700 !important;
  min-width: 120px !important;

  &:hover {
    background-color: #12491d !important;
    color: #fff !important;
  }
}

/* Default button styles */
.outline-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 50px !important;
  color: #12491d !important;
  border-color: #12491d;
  background-color: #ffffff !important;
  border: 2px solid #12491d;
  font-weight: 700;
  min-width: 120px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #12491d !important;
    color: #fff !important;
  }
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .outline-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .outline-button {
    font-size: 12px !important;
    padding: 6px 12px;
  }
}

button {
  font-size: 16px !important;
  padding: 6px 30px;
  border-radius: 50px !important;
  color: #fff !important;
  border-color: #12491d;
  background-color: #12491d !important;
  border: 2px solid #12491d;
  font-weight: 700;
  min-width: 120px;

  &:hover {
    background-color: #ffffff !important;
    color: #12491d !important;
  }
}

h1 {
  color: #12491d;
}

.page-content {
  margin: 10px 0;
}

.outer-card {
  margin: 0 auto;
  width: 50%;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  background: #fff;
  border-radius: 13px;
  padding: 60px 10px 10px;

  @media (max-width: 768px) {
    width: 100%;
  }

  &.re-outer {
    padding: 16px 10px 10px;
  }

  h3,
  h4,
  h5,
  h6 {
    color: #12491d;
    font-weight: 700;
  }
}

.inner-card {
  box-shadow: rgb(50 50 93 / 1%) 0px 50px 100px -27px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 28%) 0px 4px 5px -2px inset;
  background: #fff;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 32px;
  position: relative;

  label {
    margin-right: 12px;
  }
}

.trans-summary {
  display: grid;
  grid-template-columns: 35% 60%;
  grid-gap: 30px;

  @media (max-width: 992px) {
    grid-template-columns: auto;
    grid-gap: 1rem;
  }
}

.latest-earning {
  .outer-earn {
    margin: 0 auto;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    background: #fff;
    border-radius: 13px;
    padding: 10px;
  }

  .inner-earn {
    box-shadow: rgb(50 50 93 / 2%) 0px 50px 100px -27px,
      rgb(0 0 0 / 30%) 0px 30px 60px -30px,
      rgb(10 37 64 / 27%) 0px 1px 7px -1px inset;
    background: #fff;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;

    h6,
    .value {
      border-bottom: 1px solid #d5d5d5;
    }

    .value {
      display: flex;
      justify-content: space-between;

      &.last-child {
        margin-bottom: 24px;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #12491d;
      }
    }

    .down-arrow {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      box-shadow: rgb(50 50 93 / 2%) 0px 50px 100px -27px,
        rgb(0 0 0 / 30%) 0px 30px 60px -30px,
        rgb(10 37 64 / 27%) 0px 1px 7px -1px inset;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -10px;
      background: #fff;
      left: 50%;
      transform: translate(-50%);

      &:hover {
        background-color: #12491d5f;
      }

      a {
        margin: 0;

        svg {
          width: 15px;
        }
      }
    }
  }
}

.sgd {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    margin: 0 auto;
  }
}

.both-shadow {
  border: 2px solid #ffffff;
  border-radius: 50px;
  box-shadow: inset 0 0 8px #229300;
  outline: 2px solid #44cd60;
}

.light-txt {
  color: gray;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.ref-content {
  display: flex;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin: 12px 0;

  @media (max-width: 992px) {
    display: block;

    .ref-id {
      margin-top: 12px;
    }
  }
}

.side_bar {
  width: 100%;
  background-color: rgb(255 255 255 / 76%);
  box-shadow: rgb(50 50 93 / 1%) 0px 50px 100px -27px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 28%) 0px 4px 5px -2px inset;

  a {
    display: block;
    color: #12491d;
    padding: 16px;
    margin-bottom: 0;
    text-decoration: none;
    border-bottom: 1px solid #afafaf;

    &:hover {
      background-color: #12491d05;
      color: #12491d;
      font-weight: 600;
    }
  }

  .active-tab {
    background-color: #12491d05;
    color: #12491d;
    font-weight: bold;
    text-decoration: underline;
  }

  .navbar-collapse {
    background-color: transparent !important;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (min-width: 768px) {
    width: 20%;
  }
}

.userPanel_navBar {
  background-color: #f5f5f5;
  box-shadow: 0 0px 16px rgb(0 0 0 / 16%), 0 2px 3px rgb(0 0 0 / 15%);

  .navbar-collapse {
    background-color: transparent !important;
    padding: 16px;

    @media (max-width: 768px) {
      padding: 0 !important;
      margin: 0 !important;
    }

    .sideBar_contents {
      display: block;
      color: #12491d;
      padding: 16px;
      margin-bottom: 0;
      text-decoration: none;
      border-bottom: 1px solid #afafaf;

      &:hover {
        color: $green1;
      }

      @media screen and (min-width: 768px) {
        display: none !important;
      }
    }
  }
}

.user-section {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  .user-content {
    width: 100%;
    padding: 16px;

    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }
}

.investment_overview {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
}

.transaction_history {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
}

.invest_page {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
}

.investment_value {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
}

.interest_earned {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }

    h1 {
      margin-bottom: 32px;
    }

    table {
      min-width: 320px;

      thead {
        tr {
          background-color: #d9d9d9;

          th {
            border-bottom: none;
          }
        }
      }

      tbody {
        tr {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.refferal_commission {
  display: flex;
  flex-wrap: nowrap;
  background-color: rgb(255, 255, 255) !important;
  background-image: url("/img/d2.png"), url("/img/d1.png"), url("/img/d3.png");
  background-position: right center, 0px -54px, 128px bottom;
  background-repeat: no-repeat;
  background-size: 300px;
  min-height: 320px;

  .content_bar {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media screen and (min-width: 768px) {
      width: 75%;
    }

    table {
      min-width: 320px;
    }
  }
}

.withdraw_funds {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      width: 75%;
    }

    h1 {
      margin-bottom: 32px;
    }

    table {
      min-width: 320px;

      thead {
        tr {
          background-color: #d9d9d9;

          th {
            border-bottom: none;
          }
        }
      }

      tbody {
        tr {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.withdraw_funds_2 {
  display: flex;
  flex-wrap: wrap;

  .content_bar {
    width: 100%;
    padding: 16px;

    @media screen and (min-width: 768px) {
      width: 75%;
    }

    .withdraw_funds_content {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
      padding: 72px;

      @media screen and (max-width: 576px) {
        padding: 16px;
      }

      .country {
        column-gap: 50px;
      }

      .form-control {
        margin-left: auto;

        &:focus-visible {
          outline: none;
        }

        &:focus {
          box-shadow: none;
          border-color: rgb(0 0 0 / 23%);
        }
      }

      .btn-primary {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid rgb(0 0 0 / 23%);
        padding: 2px 8px;

        &:hover {
          background-color: rgb(0 0 0 / 19%);
        }
      }
    }
  }
}
