.activeLevel {
  font-weight: bolder;
}

.expand-arrow {
  transition: transform 0.2s linear;
}

.ref-expand {
  transform: rotateZ(-90deg);
}

.level-bar {
  position: absolute;
  border: 2px solid black;
  border-radius: 10%;
  height: calc(100% - 49px);
  top: 60px;
  left: 2.5%;
  top: 48px;
  opacity: 0;
  -moz-transition: opacity 2s ease 0.3s;
  -webkit-transition: opacity 2s ease 0.3s;
  -o-transition: opacity 2s ease 0.3s;
  transition: opacity 2s ease 0.3s;
}

.level-container {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  background-color: rgba(255, 255, 255, 0.4509803922);
  border-radius: 13px;
}
