$green1: #0e7f4b;
$green2: #21aa20;
$dark1: #1c1a2fde;
$shutterstock_footer_bg: url("./img/david-gabric-Kd.png");
$section_5: url("./img/section_5.png");
$szkk_a1yn: url("./img/szkk_a1yn.jpg");
$section_7_bg: url("./img//section_7_bg.png");
$top_section_bg_1: url("./img/to_section_bg_1.png");
$top_section_bg_2: url("./img/top_section_bg_2.png");
$section_5_farming_1: url("./img/farming_1.png");
$section_6_bg: url("./img/section_6_bg.png");
$section_8_bg: url("./img//section_8_bg.png");
$invest_in_cannabis_section_3: url("./img/invest_in_cannabis_section_3_bg.png");

.amount-dropdown {
  display: flex;
  align-items: center;
  margin: 16px 0;
  gap: 10px;

  label {
    margin: 0 !important;
  }

  select {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 50px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px 0px 5px 0px inset;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }
  }
}

.navbar {
  font-family: Montserrat !important;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px #b0b0b0;

  .navbar-brand {
    .logos {
      gap: 6px;
    }

    &:hover {
      text-decoration: none !important;
    }

    img {
      width: 120px;
    }

    @media screen and (min-width: 768px) {
      img {
        width: 120px;
      }
    }
  }

  .partner-text {
    font-weight: 700;
    display: block;
    font-size: 7px;
    text-decoration: none;
    color: #12cd51;
    margin-top: -14px;
    margin-left: 26px;
  }

  .navbar-toggler {
    padding: 4px 8px !important;
    min-width: 70px !important;
    display: none;
  }

  .navbar-collapse {
    justify-content: end;
    margin-left: -12px;
    margin-right: -12px;

    .nav-link {
      color: #ffffff;
      font-size: 12px;

      &:hover {
        color: #ffffff;
      }

      @media screen and (min-width: 768px) {
        font-size: 10px;
      }

      @media screen and (min-width: 832px) {
        font-size: 12px;
      }
    }

    @media screen and (min-width: 768px) {
      background-color: #ffffff !important;
      margin-left: 0px;
      margin-right: 0px;

      .nav-link {
        color: #000000;
        box-shadow: 0px 2px 4px #b0b0b0;
        border-radius: 14px;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 0px !important;
        padding: 2px 8px;

        &:hover {
          box-shadow: 0px 2px 4px #b0b0b0 inset;
          color: #000000;
        }
      }
    }
  }

  .lang_btn {
    box-shadow: 0px 2px 4px #b0b0b0;
    border-radius: 14px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 0px !important;
    padding: 2px 2px;
    width: 54px;

    &:hover {
      box-shadow: 0px 2px 4px #b0b0b0 inset;
    }

    &:active {
      border-color: #ffffff !important;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }

  .dropdown {
    .dropdown-menu {
      border: none;
      border-radius: 16px;
      padding: 0;
      box-shadow: 0px 2px 4px #b0b0b0 inset;
      min-width: 214px;

      li {
        a {
          display: block;
          box-shadow: 0px 1px 2px #b0b0b0;
          padding: 4px 16px;
          text-decoration: none;
          color: #000000;
          margin-bottom: 0;

          &:hover {
            background-color: $green1;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.footer {
  background-image: $shutterstock_footer_bg;
  background-position: center;
  background-size: cover;

  .inner_box {
    background-image: linear-gradient(180deg, transparent, $dark1);
    padding: 42px 32px 200px 32px;
    font-family: Montserrat;

    @media (max-width: 768px) {
      text-align: center;
    }

    h1 {
      font-weight: 700;
      color: #ffffff;
      text-align: end;
      font-size: 32px;
    }

    .social_links {
      display: flex;
      align-items: center;
      justify-content: end;

      // @media (max-width: 768px) {
      //   justify-content: center;
      // }

      a {
        margin-right: 16px;

        @media (max-width: 768px) {
          margin-right: 8px;
        }

        &:hover {
          transform: scale(0.9);
          transition-duration: 0.3s;
          transition-property: transform;
        }

        .icon {
          color: #ffffff;

          // @media (max-width: 768px) {
          //   width: 23px;
          //   height: 23px;
          // }
        }
      }
    }
  }
}

.landing_page {
  font-family: Montserrat !important;
  min-height: 100vh;

  .top_section {
    background-image: $top_section_bg_1;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    width: 70%;
    margin-left: auto;
    margin-top: 16px;

    &:hover {
      .inner_box {
        visibility: visible;
        opacity: 1;
        color: #ffffff !important;

        div {
          transform: translateY(0px);
          visibility: visible;
        }

        h1 {
          color: #ffffff !important;
        }

        .btn-primary {
          // transform: scaley(1);
          visibility: visible;
          color: #ffffff !important;
          background-color: #1c1a2f70 !important;
          border: 2px solid #ffffff !important;
        }
      }
    }

    .inner_box {
      background-image: $top_section_bg_2;
      background-size: cover;
      background-position: left top;
      background-repeat: no-repeat;
      text-align: center;
      padding: 42px 32px;
      color: transparent;
      visibility: hidden;
      transition: all 0.3s;
      opacity: 0;

      div {
        overflow: hidden;
        visibility: hidden;
        transform: translateY(10px);
        transition: all 0.3s;

        h1 {
          color: transparent;
          transition: all 0.3s;
        }
      }

      .btn-primary {
        background-color: transparent !important;
        border-radius: 20px;
        border: 2px solid transparent !important;
        color: transparent !important;
        padding-right: 34px;
        padding-left: 34px;
        font-weight: 600;
        // transform: scaley(0.2);
        visibility: hidden;
        transition: all 0.3s;

        &:hover {
          background-color: #ffffff !important;
          border-color: #ffffff !important;
          color: #000000 !important;
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      .inner_box {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }

        .btn-primary {
          font-size: 12px;
        }
      }
    }
  }

  .section_3 {
    margin-top: 32px;
    margin-bottom: 32px;

    .inner_box {
      background-color: #0e7f4b15;
      margin-right: auto;
      width: 735px;
      display: flex;
      flex-direction: row;

      .about {
        color: $green1;
        padding: 32px 0px 32px 32px;

        h5 {
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .img_right {
        display: flex;
        align-items: center;

        img {
          width: 225px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .inner_box {
        width: 100%;
        flex-direction: column;
        margin-left: 0;

        .about {
          padding-right: 32px;
        }
      }
    }
  }

  .section_4 {
    display: flex;
    flex-wrap: wrap;

    .left_side {
      background-image: url(./img/marketing_overview_bg.png);
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 1000px;
      margin-bottom: 32px;
      color: #ffffff;
      padding: 32px;

      &:hover {
        .inner_box {
          opacity: 1;

          .inner_right {
            transform: translateX(0px);
          }
        }
      }

      h4 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 36px;
        color: #ffffff;
      }

      .inner_box {
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
        transition: all 0.3s;

        .inner_left {
          width: 50%;
          padding: 16px;
        }

        .inner_right {
          width: 50%;
          padding: 16px;
          transform: translateX(20px);
          transition: all 0.3s;
        }

        .items {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            width: 72px;
            margin-right: 16px;
          }

          p {
            font-size: 13px;
            font-weight: 600;
            line-height: 22px;
          }

          @media screen and (max-width: 765px) {
            flex-direction: column;

            p {
              text-align: center;
            }
          }
        }

        @media screen and (max-width: 765px) {
          .inner_left {
            width: 100%;
          }

          .inner_right {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 765px) {
      .left_side {
        width: 100%;
        background-position: center;
      }
    }
  }

  .section_5 {
    padding: 16px;

    .inner_box {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .farming_img {
        width: 100%;
        background-image: $section_5_farming_1;
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 185px;
      }

      .farming_content {
        background-color: #e8e8e8;
        width: 100%;
        padding: 16px;
        color: $green1;
        text-align: end;

        h1 {
          font-weight: 600;
        }

        p {
          font-weight: 500;
        }
      }

      @media screen and (min-width: 768px) {
        .farming_img {
          width: 55%;
        }

        .farming_content {
          width: 45%;
        }
      }

      .farming_2 {
        position: absolute;
        top: 16px;
        left: 15%;
        width: 60%;
      }
    }
  }

  .section_6 {
    background-image: $section_6_bg;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 32px 16px;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .content_area {
      max-width: 495px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;

      @media screen and (max-width: 765px) {}
    }
  }

  .section_7 {
    background-image: $section_7_bg;
    background-position: center;
    background-size: cover;

    .inner_box {
      min-height: 820px;
      padding: 32px 16px;
      font-weight: 600;
      color: $green1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .btn-primary {
        border-radius: 20px;
        border: 2px solid $green1;
        background-color: $green1;
        color: #ffffff;
        width: 240px;
        font-weight: 600;
        margin-top: 158px;
        margin-bottom: 32px;

        &:hover {
          background-color: #ffffff;
          color: $green1;
        }
      }

      p {
        max-width: 448px;
        text-align: center;
      }
    }
  }

  .section_8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 120px;
    margin-top: 60px;

    .content_area {
      background-image: $section_8_bg;
      background-position: left top;
      background-repeat: no-repeat;
      background-size: auto 100%;
      width: 80%;
      min-height: 606px;

      .inner_box {
        background-image: linear-gradient(transform, $green1);
        height: 100%;
        width: 100%;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding: 32px;

        p {
          max-width: 684px;
          text-align: center;
        }
      }
    }

    @media screen and (max-width: 765px) {
      .content_area {
        width: 100%;
        background-position: center;
      }
    }
  }
}

.invest_in_cannabis {
  font-family: Montserrat !important;
  min-height: 100vh;

  .section_1 {
    .inner_box {
      padding: 46px 16px;
      margin-bottom: 48px;
      text-align: center;
      max-width: 940px;
      margin-left: auto;
      margin-right: auto;

      h1 {
        font-weight: 600;
        margin-bottom: 32px;
      }

      p {
        margin-bottom: 32px;
      }

      .btn-success {
        border: 2px solid;
        border-radius: 20px;
        padding-left: 32px;
        padding-right: 32px;
        font-size: 32px !important;
      }
    }
  }

  .section_2 {
    padding: 16px;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;

    h4 {
      color: $green1;
      font-weight: 600;
      text-align: center;
      margin-bottom: 64px;
    }

    p {
      text-align: center;
      font-weight: 600;
      margin-bottom: 32px;
    }

    img {
      width: 100%;
      margin-bottom: 32px;
    }

    .green1 {
      color: $green1;
      font-size: 24px;
    }

    @media screen and (max-width: 765px) {
      text-align: center;
    }
  }

  .section_3 {
    background-image: $invest_in_cannabis_section_3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 48px;

    .inner_box {
      max-width: 940px;
      margin-left: auto;
      margin-right: auto;

      color: #ffffff;
      font-weight: 600;
      padding: 56px 16px;

      h1 {
        text-align: center;
        color: #ffffff;
        margin-bottom: 32px;
      }

      p {
        text-align: center;
      }
    }
  }

  .section_4 {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;

    .content_box {
      padding: 32px 16px;
      text-align: center;

      h3 {
        font-weight: 600;
        color: $green1;
      }

      img {
        width: 100%;
        margin-bottom: 32px;
      }
    }
  }
}

// Table UI Starts Here
.trans-content {
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  background-color: #ffffff73;
  border-radius: 13px;
  padding: 0px 10px 10px;
  margin: 70px 0;

  h1 {
    color: #12491d;
  }
}

.table-responsive {
  border-radius: 9px;
}

table {
  margin-bottom: 0px !important;
  box-shadow: rgb(50 50 93 / 1%) 0px 50px 100px -27px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 28%) 0px 4px 5px -2px inset;
  border-radius: 13px;

  thead {
    tr {
      text-align: center;

      th {
        background-color: #ffffff00 !important;
        padding: 10px 10px 10px 20px;
        color: #12491d;
        white-space: nowrap;
        font-size: 12px;
        border-right: 1px solid #dee2e6;

        &:nth-last-child(1) {
          border-right: unset;
        }
      }
    }
  }

  tbody {
    tr {
      text-align: center;
      border-bottom: 1px solid #dee2e6;

      &:hover {
        background-color: #19875445;
      }

      td {
        white-space: nowrap;
        padding-left: 20px;
        font-size: 12px;
        border-right: 1px solid #dee2e6;
      }
    }
  }
}

// Table UI Ends Here

// User Panel Content Area Starts Here
.user-content {
  width: calc(100% - 230px);
  padding: 0 30px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

// User Panel Content Area Ends Here
.mr-3 {
  margin-right: 12px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn {
  border: unset;
  color: gray !important;
  background-color: white !important;
  padding: 7px 12px;
  border-radius: 3px;
  font-size: 14px;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px 0px 5px 0px inset;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer !important;
  background-color: transparent !important;
  display: block !important;
  width: 10px !important;
  outline: none;
  right: 0;
  /* height: 10px; */
}

.interest-earned-btn button {
  font-size: 16px !important;
  padding: 8px;
  border-radius: 50px !important;
  color: #fff !important;
  border-color: #12491d;
  background-color: #12491d !important;
  border: 2px solid #12491d;
  font-weight: 700;
  min-width: auto;
  margin-right: 4px !important;
}

.MuiTableCell-paddingCheckbox {
  width: 48px;
  padding: 0 0 0 4px;
  display: none !important;
}

.refferal-wrapper .MuiButtonBase-root {
  padding: 0;
  width: 100%;
}

.refferal-wrapper .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.refferal-table-wrapper .ref-table .MuiTableBody-root .MuiTableCell-root {
  padding: 0;
}

.refferal-wrapper .MuiAccordionDetails-root {
  padding: 0;
}

.inner-ref-table {
  box-shadow: none;
}

.w-160 {
  min-width: 110px;
}

.digital-wrapper {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: inset 0 0 12px #229300;
  outline: 2px solid #44CD60;
}

.digital-text {
  font-family: DS-Digital;
  font-size: 70px;
}

.main-digi {
  display: flex;
  max-width: max-content;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

// Loader CSS Starts Here
.loader-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .loader-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    #preLoader {
      z-index: 11;
      border: 7px solid #f3f3f3;
      border-radius: 50%;
      border-top: 7px solid #0e7f4b;
      width: 60px;
      height: 60px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Loader CSS Ends Here