.profile-content {
  font-family: Montserrat;
  margin-top: 30px;

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    color: #12491d;
  }

  .profile-card {
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-section {
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
      background: #fff;
      border-radius: 30px;
      display: inline-block;
      height: auto;
      margin: 0 0 70px 0;
      padding: 16px 32px 32px 32px;
      position: relative;
      width: 100%;

      .field-area {
        display: grid;
        column-gap: 50px;
        width: 100%;
        grid-template-columns: 47% 47%;

        @media (max-width: 1240px) {
          display: block;
        }

        .fields {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;

          @media (max-width: 576px) {
            display: block;
          }

          label {
            min-width: 150px;
            font-weight: 600;
          }
        }
      }

      .action-btn {
        text-align: center;
      }

      .optional-actions {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        div {
          a {
            color: #0170b9;
            font-size: 15px;
            line-height: 22px;

            &:hover {
              text-decoration: unset;
              color: #3a3a3a;
            }
          }
        }
      }
    }
  }
}
