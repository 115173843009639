.overview-section {
  margin: 10px 0;

  .transaction-hx {
    display: inline-flex;
    column-gap: 50px;
    width: 100%;

    @media (max-width: 1160px) {
      display: block;
    }

    .trans-contents {
      box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
      background: #fff;
      border-radius: 13px;
      display: inline-block;
      height: auto;
      margin: 0 0 70px 0;
      padding: 24px 16px;
      position: relative;
      width: 100%;

      ::-webkit-scrollbar {
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 6px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #777;
      }
      ::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 6px;
      }

      .custom-scroll {
        scrollbar-width: thin;
        scrollbar-color: #a0a0a0 #f0f0f0;
      }

      @media (max-width: 1160px) {
        margin: 0 0 35px 0;
      }

      .invest-details {
        display: flex;
        justify-content: space-between;
      }

      p {
        &:nth-child(2) {
          color: #0e7f4b;
        }
      }
    }
  }
}

.trans-sep {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 30px;
}

h6 {
  font-weight: 700;
  color: #12491d;
}

.w-120 {
  width: 120px;
}

.light-txts {
  color: gray;
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
}

.light-txts {
  b {
    font-weight: 600;
  }
}

.down-arrow {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  box-shadow: rgb(50 50 93 / 2%) 0px 50px 100px -27px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 27%) 0px 1px 7px -1px inset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  background: #fff;
  left: 50%;
  transform: translate(-50%);

  &:hover {
    background-color: #12491d5f;
  }

  a {
    margin: 0;

    svg {
      width: 15px;
    }
  }
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 6px;
}

.table-scroll {
  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 #f0f0f0;
  overflow-y: scroll;
  max-height: 800px; /* Adjust the max height as needed */
}


.both-shadow {
  @media (max-width: 992px) {
    margin: 0 auto;
  }
}

.inner-card .recent-trans {
  @media (max-width: 992px) {
    text-align: center;
  }
}
