.investor-section {
  font-family: Montserrat;
  margin: 70px auto;
  max-width: 90%;


  .investor-content {
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    padding: 10px;
    border-radius: 13px;
    background-color: #ffffff73;

    h2 {
      padding-left: 10px;
      font-weight: 700;
      font-size: 35px;
      margin-bottom: 10px;
    }

    table {
      margin-bottom: 50px;

      thead {
        tr {
          th {
            background-color: #198754;
            padding: 10px 10px 10px 20px;
            color: #12491d;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: #19875445;
          }

          td {
            white-space: nowrap;
            padding-left: 20px;
            font-size: 12px;
          }
        }
      }
    }
  }
}