.invest-section {
  margin: 10px 0;

  h1 {
    color: #12491d;
    text-align: center;
  }

  .main-invest {
    margin: 0 auto;
    width: 50%;
    margin: 0 auto;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    background: #fff;
    border-radius: 13px;
    padding: 60px 10px 10px;

    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      max-height: 80%;
      /* Set a maximum height for the popup */
      overflow-y: auto;
      /* Enable vertical scrolling when content exceeds max height */
    }

    .popup-content {
      max-height: 300px;
      /* Adjust the desired maximum height */
      overflow-y: auto;
      padding: 10px;

      /* Customize the scrollbar */
      scrollbar-width: thin;
      /* Firefox */
      scrollbar-color: #888 #f5f5f5;
      /* Firefox */

      /* WebKit (Chrome, Safari) scrollbar customization */
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin: 1rem !important;
      }

      ul li {
        margin-bottom: 10px;
      }

      ul ul {
        margin-left: 0 !important;
        margin-right: 0 !important;
        list-style-type: disc;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      ul ul li {
        margin-left: 20px;
      }
    }

    .scrollable-content {
      margin: 0;
    }

    .popup.open {
      display: block;
    }

    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
    }

    .terms-section {
      display: flex;
      flex-direction: row;
      /* Set to row */
      flex-wrap: wrap;
      /* Allow wrapping if needed */

      // margin-top: 20px;
      // align-items: center;
    }

    .terms-section label,
    .terms-section p {
      flex: 1;
      /* Allow both elements to grow and shrink */
    }

    .terms-section label {
      flex: 0 0 auto;
      /* Don't grow, don't shrink, auto basis */
      width: 15px;
      /* Adjust as needed */
      // display: inline-flex;
      // flex-wrap: nowrap;
      // align-items: baseline;
      cursor: pointer;
    }

    .custom-label {
      color: black;
      font-size: 12px;
      cursor: pointer;
    }

    .close-icon {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      color: #333;
      /* Adjust the color as needed */
      margin-left: 10px;
    }

    @media (max-width: 1100px) {
      width: 75%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    .invest-card {
      box-shadow: rgb(50 50 93 / 1%) 0px 50px 100px -27px,
        rgb(0 0 0 / 30%) 0px 30px 60px -30px,
        rgb(10 37 64 / 28%) 0px 4px 5px -2px inset;
      background: #fff;
      border-radius: 13px;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 32px;
      position: relative;

      @media (max-width: 1160px) {
        width: 100%;
      }

      .wallet-address {
        word-break: break-all;
      }

      .country {
        column-gap: 50px;
        width: 100%;
      }

      label {
        white-space: nowrap;
        margin-top: auto;
      }
    }
  }
}